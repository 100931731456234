import React, { useEffect, useState } from "react"
import ReactMarkdown from 'react-markdown'

import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Alert from "react-bootstrap/Alert"

function App() {
  const [apiKeyState, setApiKeyState] = useState("")
  const [file, setFile] = useState(null)
  const [transcription, setTranscription] = useState("")
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [summary, setSummary] = useState("")

  const setApiKey = (newApiKey) => {
    localStorage.setItem("apiKey", newApiKey)
    setApiKeyState(newApiKey)
  }

  useEffect(() => {
    const storedApiKey = localStorage.getItem("apiKey")
    if (storedApiKey) {
      setApiKeyState(storedApiKey)
    }
  }, [])

  const handleFileChange = (event) => {
    setFile(event.target.files[0])
  }

  const handleTranscribe = async () => {
    try {
      setError(null)
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("model", "whisper-1")
      formData.append("response_format", "text")

      const response = await fetch(
        "https://api.openai.com/v1/audio/transcriptions",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${apiKeyState}`,
          },
          body: formData,
        }
      )

      if (!response.ok) {
        const obj = await response.json()
        throw new Error(obj.error.message)
      }

      const data = await response.text()
      setTranscription(data)
      await handleSummarize(data)
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }

  const handleSummarize = async (text) => {
    try {
      const formData = {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "Du hast ein Transkript einer Besprechung erhalten.",
          },
          {
            role: "system",
            content:
              "Teilen Sie den Inhalt der Besprechung in mehrere Abschnitte und geben Sie diesen Teilen eine Überschrift",
          },
          {
            role: "system",
            content:
              "Teile jeden Abschnitt in Unterpunkte auf und fasse die wichtigsten Punkte im Detail zusammen.",
          },
          {
            role: "system",
            content:
              "Dein Ergebnis wird von der Pressesprecherin der Stadtwerke verwendet, um die Öffentlichkeit über die relevanten Themen der Besprechung zu informieren.",
          },
          {
            role: "system",
            content:
              "Gehe möglichst detailliert auf die wichtigsten Punkte ein, damit die Pressesprecherin später wichtige Infos herausfiltern kann.",
          },
          { role: "user", content: text },
        ],
      }

      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${apiKeyState}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      )

      if (!response.ok) {
        const obj = await response.json()
        throw new Error(obj.error.message)
      }

      const data = await response.json()
      setSummary(data.choices[0].message.content)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <Container>
      <h1 className="my-3">Audio Transkription</h1>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>OpenAI API Key</Form.Label>
          <Form.Control
            type="text"
            value={apiKeyState}
            onChange={(e) => setApiKey(e.target.value)}
            disabled={loading}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>File Upload</Form.Label>
          <Form.Control
            type="file"
            accept="audio/*"
            onChange={handleFileChange}
            disabled={loading}
          />
        </Form.Group>
        <Button className="mb-3" onClick={handleTranscribe} disabled={loading}>
          {!loading && "Transkription starten"}
          {loading && "Transkription lädt..."}
        </Button>
      </Form>
      {error && <Alert variant="danger">{error.message}</Alert>}

      {transcription && (
        <Form.Group className="mb-3">
          <Form.Label>Transkription</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Leave a comment here"
            style={{ height: "100px" }}
            value={transcription}
            readOnly
          />
        </Form.Group>
      )}
      {summary && (
        <Form.Group className="mb-3">
          <Form.Label>Zusammenfassung</Form.Label>
          <ReactMarkdown>{summary}</ReactMarkdown>
        </Form.Group>
      )}
    </Container>
  )
}

export default App
